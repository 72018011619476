import React, {useEffect, useState } from 'react'
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Aos from "aos"
import "aos/dist/aos.css"

const Swiperr = () => {
  const [That, setThat] = useState("")
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <>
      <h2 className="text-center font-bold grade md:text-2xl firstlowfontw-lh mb-[30px] content-2 uppercase pt-10" data-aos="fade-up">Testimonials
      </h2>
      <h2 className="text-center md:text-2xl firstlowfontw-lh font-bold grade mb-[60px]" data-aos="fade-up">View what some of our esteemed clients say about us</h2>


      <Swiper slidesPerView={1}  modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')} className='swiper-slide'>
        <SwiperSlide className='swiper-child'>
          <div className='md:w-1/3'>
            <img className='hidden rounded-full mb-[15px] w-16 h-16 object-cover md:static absolute top-[-30px] left-0 right-0 md:mx-0 mx-auto md:block' src={"../images/image -203.png"} alt="img" />
            <div className='flex md:flex-col flex-row gap-3 md:gap-1 md:mt-0 mt-3 md:space-y-1 space-x-3 md:space-x-0 md:item-strech item-center'>
              <h2 className="whitespace-nowrap font-bold font">Truth James</h2>
              <p className='text-slate-500 font'>Estate Agent</p>
              <div className="rate flex space-x-2 item-center border-l-2 border-green-400">
                {/* star icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-700 ml-[2px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
                <span className='font-bold text-red-700'>5.0</span>
              </div>
            </div>
          </div>

          <div className='w-[100%] text-slate-500 md:border-l-2 md:pl-3 pl-[0px] md:border-b-0 border-green-300 size border-b-2 md:w-2/3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ex ea repudiandae itaque sint ipsam consequuntur iusto. Adipisci commodi, dolorum, sit tenetur pariatur illum rerum accusantium sapiente sed ab nisi!
          </div>

        </SwiperSlide>



        <SwiperSlide className='swiper-child'>
          <div className='md:w-1/3'>
            <img className='hidden rounded-full mb-[15px] w-16 h-16 object-cover md:static absolute top-[-30px] left-0 right-0 md:mx-0 mx-auto md:block' src={"../images/image -203.png"} alt="img" />
            <div className='flex md:flex-col flex-row gap-3 md:gap-1 md:mt-0 mt-3 md:space-y-1 space-x-3 md:space-x-0 md:item-strech item-center'>
              <h2 className="whitespace-nowrap font-bold font">Iwayemi Kehinde</h2>
              <p className='text-slate-500 font'>Land lord</p>
              <div className="rate flex space-x-2 item-center border-l-2 border-green-400">
                {/* star icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-700 ml-[2px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
                <span className='font-bold text-red-700'>5.0</span>
              </div>
            </div>
          </div>

          <div className='w-[100%] text-slate-500 md:border-l-2 md:pl-3 pl-[0px] md:border-b-0 border-green-300 size border-b-2 md:w-2/3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ex ea repudiandae itaque sint ipsam consequuntur iusto. Adipisci commodi, dolorum, sit tenetur pariatur illum rerum accusantium sapiente sed ab nisi!
          </div>

        </SwiperSlide>



        <SwiperSlide className='swiper-child'>
          <div className='md:w-1/3'>
            <img className='hidden rounded-full mb-[15px] w-16 h-16 object-cover md:static absolute top-[-30px] left-0 right-0 md:mx-0 mx-auto md:block' src={"../images/image -203.png"} alt="img" />
            <div className='flex md:flex-col flex-row gap-3 md:gap-1 md:mt-0 mt-3 md:space-y-1 space-x-3 md:space-x-0 md:item-strech item-center'>
              <h2 className="whitespace-nowrap font-bold font">Maxwell Joshua</h2>
              <p className='text-slate-500 font'>Estate Agent</p>
              <div className="rate flex space-x-2 item-center border-l-2 border-green-400">
                {/* star icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-700 ml-[2px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
                <span className='font-bold text-red-700'>5.0</span>
              </div>
            </div>
          </div>

          <div className='w-[100%] text-slate-500 md:border-l-2 md:pl-3 pl-[0px] md:border-b-0 border-green-300 size border-b-2 md:w-2/3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ex ea repudiandae itaque sint ipsam consequuntur iusto. Adipisci commodi, dolorum, sit tenetur pariatur illum rerum accusantium sapiente sed ab nisi!
          </div>

        </SwiperSlide>




        
        <SwiperSlide className='swiper-child'>
          <div className='md:w-1/3'>
            <img className='hidden rounded-full mb-[15px] w-16 h-16 object-cover md:static absolute top-[-30px] left-0 right-0 md:mx-0 mx-auto md:block' src={"../images/image -203.png"} alt="img" />
            <div className='flex md:flex-col flex-row gap-3 md:gap-1 md:mt-0 mt-3 md:space-y-1 space-x-3 md:space-x-0 md:item-strech item-center'>
              <h2 className="whitespace-nowrap font-bold font">Kenny Braun</h2>
              <p className='text-slate-500 font'>Estate Agent</p>
              <div className="rate flex space-x-2 item-center border-l-2 border-green-400">
                {/* star icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-700 ml-[2px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
                <span className='font-bold text-red-700'>5.0</span>
              </div>
            </div>
          </div>

          <div className='w-[100%] text-slate-500 md:border-l-2 md:pl-3 pl-[0px] md:border-b-0 border-green-300 size border-b-2 md:w-2/3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ex ea repudiandae itaque sint ipsam consequuntur iusto. Adipisci commodi, dolorum, sit tenetur pariatur illum rerum accusantium sapiente sed ab nisi!
          </div>

        </SwiperSlide>




        
        <SwiperSlide className='swiper-child'>
          <div className='md:w-1/3'>
            <img className='hidden rounded-full mb-[15px] w-16 h-16 object-cover md:static absolute top-[-30px] left-0 right-0 md:mx-0 mx-auto md:block' src={"../images/image -203.png"} alt="img" />
            <div className='flex md:flex-col flex-row gap-3 md:gap-1 md:mt-0 mt-3 md:space-y-1 space-x-3 md:space-x-0 md:item-strech item-center'>
              <h2 className="whitespace-nowrap font-bold font">Joshua Adisa</h2>
              <p className='text-slate-500 font'>Estate Agent</p>
              <div className="rate flex space-x-2 item-center border-l-2 border-green-400">
                {/* star icon */}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-700 ml-[2px]">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>
                <span className='font-bold text-red-700'>5.0</span>
              </div>
            </div>
          </div>

          <div className='w-[100%] text-slate-500 md:border-l-2 md:pl-3 pl-[0px] md:border-b-0 border-green-300 size border-b-2 md:w-2/3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore ex ea repudiandae itaque sint ipsam consequuntur iusto. Adipisci commodi, dolorum, sit tenetur pariatur illum rerum accusantium sapiente sed ab nisi!
          </div>

        </SwiperSlide>



        
      </Swiper>













      <div className='mx-auto w-[300px] text-center my-6'>
        <button onClick={() => {setThat("Use the one at the top with BLUE color")}} className='mr-16 text-white bg-green-700 p-2 rounded-md  hover:text-green-700 hover:bg-white hover:shadow-xl transit'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15" />
          </svg>
        </button>

        
        <button onClick={() => {setThat("Use the one at the top with BLUE color")}} className='text-white bg-green-700 p-2 rounded-md hover:text-green-700 hover:bg-white hover:shadow-xl transit'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
          </svg>
        </button>
        <p className="text-red-700 text-sm text-center">{That}</p>
      </div>
    </>
  )
}

export default Swiperr

