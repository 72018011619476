import React from "react"

const Hero = () => {
  return (
    <div>
      <div className="header width flex flex-col px-5 w-[100%]
      pt-40 space-y-6">
        <h1 className="text-3xl firstlowfont md:text-6xl text-center font-bold family grad">Embrace the future of real estate with innovative technology</h1>
        <div className="md:px-40">
          <p className="md:text-2xl text-sm seclowfont text-slate-400 text-center font-medium md:px-10">Opening you up to endless possibilities and helping you find more than a house and more of a home</p>
        </div>

        <div id="buttons" className="flex justify-center space-x-7">
          <button className="filled-btn hover:bg-white">Sign Up</button>
          <button className="transparent-btn">Login</button>
        </div>

        <div className="px-8 md:w-[100%]">
          <div className="flex space-x-6 mb-4 mt-10">
            <span className="transparent-btn cursor-pointer">RENT</span>
            <span className="transparent-btn cursor-pointer">BUY</span>
            <span className="transparent-btn cursor-pointer">SELL</span>
          </div>
          <div id="last-hero" className="bg-green-700 md:flex flex-col md:flex-row justify-between gap-3 p-8 items-center h-[230px] py-3 md:py-0 md:h-[140px] rounded-3xl mt-5 mb-8 w-[100%] md:w-[100%]">
            <div className="child flex flex-col space-y-2">
              <p className="text-white text-base">Location</p>
              <input className="bg-transparent rounded-sm border outline-none h-[33px] px-[8px] caret-white text-white md:text-base text-xs" type="text" />
            </div>

            <div className="child flex flex-col space-y-2">
              <p className="text-white text-base">Property</p>
              <input className="bg-transparent rounded-sm border outline-none h-[33px] px-[8px] caret-white text-white" type="text" />
            </div>

            <div className="child flex flex-col space-y-2">
              <p className="text-white text-base">Price</p>
              <input className="bg-transparent rounded-sm border outline-none h-[33px] px-[8px] caret-white text-white" type="text" />
            </div>

            <div>
              <button className="md:block hidden text-green-700 bg-white py-3 px-5 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline-block mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                </svg>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero