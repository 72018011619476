import React from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react'
import { Dat } from "./Data-1"
import Box from "./Box"

const Deals = ({ bt }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  const newElements = Dat.map((card) => {
    return (
      <div data-aos="flip-left">
        <Box card={card} carDetail="text-red-500 text-deco" carDetail1="text-green-500" />
      </div>
    )
  })
  return (
    <div className="md:px-10 md:py-10 px-4 py-10 bg-green-100">
      <h2 className="text-center font-bold grade md:text-2xl firstlowfontw-lh mb-[30px] content-2" data-aos="fade-up">Deals</h2>
      <h2 className="text-center md:text-2xl firstlowfontw-lh font-bold grade mb-[80px]" data-aos="fade-up">Checkout some of our discounted properties</h2>


      <div className="grid-box">
        {newElements}
      </div>

      <div className='text-center text-sm mt-[70px] text-green-700 px-2 rounded-md hover:text-white hover:bg-green-700 py-2 transit cursor-pointer bg-white shadow w-[210px] mx-auto' data-aos="fade-up">{bt}</div>
    </div>
  )
}

export default Deals