import React from 'react'
import logo from "../logo.png"
import Marquee from 'react-fast-marquee'

const Footer = () => {
  return (
    <div className='bg-green-700 text-white min-h-[300px] md:px-10 px-4 py-4 md:pt-10 md:pb-4'>
      <div className="flex flex-col md:flex-row md:justify-between justify-strech gap-6 items-start md:items-center">
        <div className='md:w-1/3 w-full'>
          <div className='flex space-x-4 items-center'>
            <img src={logo} className='mb-4 w-[10%]' alt="img" />
            <p className='font-bold md:text-lg text-base'>Haven Homes</p>
          </div>
          <p className='font md:w-[320px] w-[100%]'>HavenHomes is one of the best real estate companies in Nigeria. We aim to turn your dreams into reality and also give the best prices the market has to offer. Give us a try today!</p>
        </div>

        <div className="flex flex-col md:flex-row w-2/3 md:gap-[70px] gap-[20px] justify-center">
          <div>
            <h3 className='font-bold text-lg md:mb-4 mb-1 text-left'>About</h3>
            <div className="links">
              <p className="text">About Us</p>
              <p className="text">Blog</p>
              <p className="text">Properties</p>
              <p className="text">Carrer</p>
            </div>
          </div>

          <div>
            <h3 className='font-bold text-lg md:mb-4 mb-1  text-left'>Support</h3>
            <div className="links">
              <p className='text'>Contact Us</p>
              <p className='text'>Privacy</p>
              <p className='text'>Online Chat</p>
            </div>
          </div>

          <div>
            <h3 className='font-bold text-lg md:mb-4 mb-1 text-left'>Resources</h3>
            <div className="links">
              <p className='text'>FAQ</p>
              <p className='text'>Payment</p>
              <p className='text'>Tutorial</p>
            </div>
          </div>

        </div>

        <div className='w-full border-t-4 border-white md:pl-8 md:border-l-4 md:border-t-0 pt-8 md:pt-0 md:border-t-0 md:w-1/3'>
          <h3 className='font-bold text-lg text-left md:mb-4 mb-1'>Get In Touch</h3>
          <p className="text">
            <span className='block'>STATELINE ROAD FUTA</span>
            <span className='block'>+234XXXXXXXXXX</span>
            <span className='block'>iwayemikehinde1@gmail.com</span>
          </p>
        </div>

      </div>

      <Marquee speed={100}>
        <div className='text-center mt-20'>
          <h4>All RIGHT RESERVED</h4>
          <h4>React JS . Tailwind CSS</h4>
        </div>
      </Marquee>

    </div>
  )
}

export default Footer