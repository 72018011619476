import React, { useEffect, useState } from 'react'
import logo from "../logo.png"

const Navbar = () => {
  const [nav, setNav] = useState(true)
  const [scrollPos, setScrollPos] = useState(0)
  const handleClick = () => {
     setNav((prev) => (
       prev === false ? true : false
     ))
    // setNav(!nav)
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.scrollY)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <div className={scrollPos > 100 ? "flex py-3 px-5 fixed width justify-between items-center z-50 bg-white transit shadow-2xl" : "bg-transparent flex py-3 px-5 fixed width justify-between items-center z-50 transit shadow-none"}>
      <div className="flex space-x-4 items-center cursor-pointer">
        <img src={logo} className='md:w-[50px] w-[30px]' alt="" />
        <h3 className={scrollPos > 100 ? 'font-bold text-sm md:text-xl text-green-700' : 'font-bold text-sm md:text-xl text-white'}>Haven Homes</h3>
      </div>
      <ul id='nav' className={nav ? "flex flex-col md:flex-row space-y-3 absolute bg-white md:bg-transparent top-20 bottom-[0] right-20 h-[min-content] md:px-3 md:py-3 pt-[6px] w-[150px] text-center md:static md:bg-transparent md:space-y-0 md:space-x-10 md:p-[initial] md:w-min md:h-min scale-[0] md:scale-[1] transit shadow-2xl md:shadow-none" : "flex flex-col md:flex-row space-y-3 absolute bg-white bg-transparent top-20 bottom-[0] right-20 h-[min-content] md:px-3 md:py-3 pt-[6px] w-[150px] text-center md:static md:space-y-0 md:space-x-10 md:p-[initial] md:w-min md:h-min scale-[1] shadow-2xl md:shadow-none rounded-lg transit z-50"}>
        <li className={scrollPos > 100 ? 'hover:text-green-700 cursor-pointer content text-sm md:text-base border-b md:border-b-0' :'hover:text-green-300 md:text-white text-black cursor-pointer content text-sm md:text-base border-b md:border-b-0'}>Home</li>
        <li className={scrollPos > 100 ? 'hover:text-green-700 cursor-pointer content text-sm md:text-base border-b md:border-b-0' :'hover:text-green-300 md:text-white text-black cursor-pointer content text-sm md:text-base border-b md:border-b-0'}>About</li>
        <li className={scrollPos > 100 ? 'hover:text-green-700 cursor-pointer content text-sm md:text-base border-b md:border-b-0' :'hover:text-green-300 md:text-white text-black cursor-pointer content text-sm md:text-base border-b md:border-b-0'}>Services</li>
        <li className={scrollPos > 100 ? 'hover:text-green-700 cursor-pointer content text-sm md:text-base border-b md:border-b-0' :'hover:text-green-300 md:text-white text-black cursor-pointer content text-sm md:text-base border-b md:border-b-0'}>Blog</li>
        <li className={scrollPos > 100 ? 'hover:text-green-700 cursor-pointer content text-sm md:text-base md:border-b-0' :'hover:text-green-300 md:text-white text-black cursor-pointer content text-sm md:text-base md:border-b-0'}>Help</li>
      </ul>
      <div id="buttons" className='hidden md:flex space-x-7'>
        <button className="filled-btn shadow">Sign Up</button>
        <button className="transparent-btn">Login</button>
      </div>

      {nav ? (<div className='md:hidden block cursor-pointer' onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className={scrollPos > 100 ? "w-8 h-8 text-black" : "w-8 h-8 text-white"}>
          <path fillRule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.166a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
        </svg>
      </div>) : <div className='md:hidden block cursor-pointer' onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={scrollPos > 100 ? "w-8 h-8 text-black" : "w-8 h-8 text-white"}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
        </svg>

      </div>}
    </div>
  )
}

export default Navbar
