import React, { useEffect, useState } from 'react'
import Navbar from './Components/Navbar'
import Hero from './Components/Hero'
import Trust from './Components/Trust'
import Categories from './Components/Categories'
import Deals from './Components/Deals'
import Agents from './Components/Agents'
import Swiperr from './Components/Swiper'
import Dreams from './Components/Dreams'
import BeforeFooter from './Components/BeforeFooter'
import Footer from './Components/Footer'
import Loading from './Loading'

const App = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 2500)
  }, [])
  return (
    <>
      {show ? (
        <>
          <Navbar />
          <Hero />
          <Trust btn="VIEW ALL PROPERTIES" />
          <Categories />
          <Deals bt="SHOW ALL PROPERTIES" />
          <Agents bta="VIEW MORE" />
          <Swiperr />
          <Dreams />
          <BeforeFooter />
          <Footer />
        </>
      ) : <Loading />}

    </>
  )
}

export default App

