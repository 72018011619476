import React from 'react'

const BeforeFooter = () => {
  return (
    <div className='before-footer md:px-20 md:py-10 mt-32 px-4 py-10 bg-white flex flex-col justify-center item-center space-y-4'>
      <h2 className='text-center text-white font-bold text-lg md:text-2xl' data-aos="fade-up">Wants To Get Update On New Listing ?</h2>
      <p className='text-center text-white font-bold text-md md:text-xl' data-aos="fade-up">Subscribe to our news letter</p>
      <div className='mx-auto relative md:w-[60%] w-[100%]' data-aos="flip-right">
        <input type="text" className='w-[100%] p-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500'/>
        <button className=' absolute top-0 right-[0] bg-green-700 text-white h-full rounded-l-full p-3'>SUBSCRIBE</button>
      </div>
    </div>
  )
}

export default BeforeFooter