import React from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react'

const Dreams = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div className='md:px-20 md:py-10 mt-20 px-4 py-10 bg-green-100'>
      <div className="flex justify-center items-center md:justify-end space-y-28 md:space-x-28 md:space-y-0 md:items-center flex-col md:flex-row">
        <div data-aos="flip-right">
          <img src="../images/rotate.jpeg" alt="img" className="rotate-45 filte rounded-3xl w-[320px] h-[320px] change" />
        </div>
        <div className='flex flex-col space-y-4' data-aos="flip-up">
          <h2 className='grade md:text-2xl text-lg text-center md:text-right'>Let us help you turn your home dreams into reality</h2>
          <button className='transparent-btn w-[200px] mx-auto' data-aos="flip-up">Get Started</button>
        </div>
      </div>
    </div>
  )
}

export default Dreams