const Data = [
  {
    id: 1,
    bagde: "₦11,097,000",
    thumb: "thumb-1.png",
    location: "Ozumba, Victoria Island",
    description: "Fully serviced detached duplex",
    facilitiesOne: "3 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "1800 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
  {
    id: 2,
    bagde: "₦22,097,000",
    thumb: "thumb-2.png",
    location: "Auckland, Newzealand",
    description: "Fully serviced detached duplex",
    facilitiesOne: "4 beds",
    facilitiesTwo: "6 baths",
    facilitiesThree: "3600 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
  {
    id: 3,
    bagde: "₦13,190,000",
    thumb: "thumb-3.png",
    location: "Akure, OndoState",
    description: "Medium serviced detached duplex",
    facilitiesOne: "5 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "1200 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
  {
    id: 4,
    bagde: "₦6,027,000",
    thumb: "thumb-4.png",
    location: "Calabar, Cross-river",
    description: "Fully serviced detached Hunt",
    facilitiesOne: "4 beds",
    facilitiesTwo: "2 baths",
    facilitiesThree: "700 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
  {
    id: 5,
    bagde: "₦8,097,000",
    thumb: "thumb-5.png",
    location: "Ikorodu, Lagos",
    description: "Fully serviced detached duplex",
    facilitiesOne: "3 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "1800 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
  {
    id: 6,
    bagde: "₦99,097,000",
    thumb: "thumb-2.png",
    location: "Toronto, Canada",
    description: "Fully serviced detached bungalow",
    facilitiesOne: "2 beds",
    facilitiesTwo: "5 baths",
    facilitiesThree: "2200 sqft",
    details: "View Details",
    button: "BUY NOW"
  },
]







const Dat = [
  {
    id: 1,
    bagde: "New In",
    thumb: "thumb-1.png",
    location: "Ozumba, Victoria Island",
    description: "Fully serviced detached duplex",
    facilitiesOne: "3 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "1800 sqft",
    details: "$103,000,000",
    button: "$76,000,000"
  },
  {
    id: 2,
    bagde: "New In",
    thumb: "thumb-2.png",
    location: "Carlifonia, USA",
    description: "Excellent serviced detached duplex",
    facilitiesOne: "3 beds",
    facilitiesTwo: "7 baths",
    facilitiesThree: "3400 sqft",
    details: "$121,000,000",
    button: "$99,000,000"
  },
  {
    id: 3,
    bagde: "New In",
    thumb: "thumb-3.png",
    location: "Akure, OndoState",
    description: "Medium serviced detached duplex",
    facilitiesOne: "5 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "1100 sqft",
    details: "$200,000,000",
    button: "$166,000,000"
  },
  {
    id: 4,
    bagde: "Sold Out",
    thumb: "thumb-4.png",
    location: "Calabar, Cross-river",
    description: "Fully serviced detached island",
    facilitiesOne: "4 beds",
    facilitiesTwo: "2 baths",
    facilitiesThree: "700 sqft",
    details: "$20,000,000",
    button: "$17,000,000"
  },
  {
    id: 5,
    bagde: "New In",
    thumb: "thumb-5.png",
    location: "Ikorodu, Lagos",
    description: "Fully serviced detached duplex",
    facilitiesOne: "3 beds",
    facilitiesTwo: "3 baths",
    facilitiesThree: "5200 sqft",
    details: "$320,000,000",
    button: "$290,000,000"
  },
  {
    id: 6,
    bagde: "New In",
    thumb: "thumb-2.png",
    location: "Toronto, Canada",
    description: "Fully serviced detached bungalow",
    facilitiesOne: "2 beds",
    facilitiesTwo: "5 baths",
    facilitiesThree: "2200 sqft",
    details: "$99,097",
    button: "$66,000"
  },
]

export {Data, Dat}