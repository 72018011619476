import React from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react'
import Card from './Card'

const Agents = ({ bta }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  return (
    <div className="md:px-10 md:py-10 px-4 py-10 bg-green-100">
      <h2 className="text-center font-bold grade md:text-2xl firstlowfontw-lh mb-[30px] content-2" data-aos="fade-up">AGENTS</h2>
      <h2 className="text-center md:text-2xl firstlowfontw-lh font-bold grade mb-[60px]" data-aos="fade-up">Meet some of our exceptional agents</h2>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:space-x-4 space-y-16 justify-items md:space-y-0 '>
        <div data-aos="fade-up">
          <Card name="Kehinde Go" desc="Backend Developer" imgUrl="image -203.png" fb="download-1.png" tw="download-2.png" li="img4.jpg" ins="img-5.png" />
        </div>
        <div data-aos="fade-down">
          <Card name="Joshua Node" desc="Real Estate Agent" imgUrl="image-204.png" fb="download-1.png" tw="download-2.png" li="img4.jpg" ins="img-5.png" />
        </div>
        <div data-aos="fade-up">
          <Card name="Maxwell PHP" desc="Backend Developer" imgUrl="image-205.png" fb="download-1.png" tw="download-2.png" li="img4.jpg" ins="img-5.png" />
        </div>
      </div>
      <div className='text-center text-sm mt-[70px] text-green-700 px-2 rounded-md hover:text-white hover:bg-green-700 py-2 transit cursor-pointer bg-white shadow w-[210px] mx-auto' data-aos="fade-up">{bta}</div>
    </div>
  )
}

export default Agents