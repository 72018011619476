import React from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import {useEffect} from "react"

const Categories = () => {
  useEffect(() => {
    Aos.init()
  }, [])
  
  return (
    <div className="md:padding py-10 mb-[100px] px-3 bg-white">
      <h2 className="text-center md:text-2xl firstlowfontw-lh font-bold grade mb-[60px]" data-aos='fade-right'>We offer properties in the following categories</h2>

      <div className="grid-images flex flex-col gap">
        <div className="fir-sec flex gap-5 justify-center">

          <div className="relative" data-aos="flip-right">
            <img className="rounded-2xl h-[100%] w-[100%] filte object-cover" src="../images/categories-1.png" alt="img" />
            <div className="absolute md:top-[0px] top-[-10px] right-[0] round bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[10px] text-[12px]">Hotels</div>

            <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[10px] text-[12px]">25 listing</div>
          </div>


          <div className="flex gap-5 flex-col md:flex-row" data-aos="fade-right">
            <div className="relative">
              <img className="rounded-2xl filte" src="../images/categories-2.png" alt="img" />
              <div className="absolute top-[-10px] right-[0] round bg-green-700 text-white md:p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:top-[0px] md:text-base p-[6px] text-[10px]">Office</div>

              <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[6px] text-[10px]">45 listing</div>
            </div>
            <div className="relative">
              <img className="rounded-2xl filte" src="../images/categories-3.png" alt="img" />
              <div className="absolute top-[-10px] right-[0] round bg-green-700 text-white md:p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:top-[0px] md:text-base p-[6px] text-[10px]">Farmland</div>

              <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[6px] text-[10px]">76 listing</div>
            </div>
          </div>
        </div>
        <div className="sec-sec flex gap-5 justify-center">
          <div className="flex flex-col md:flex-row gap-5" data-aos="fade-left">
            <div className="relative">
              <img className="rounded-2xl filte" src="../images/categories-4.png" alt="img" />
              <div className="absolute top-[-10px] right-[0] round bg-green-700 text-white md:p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:top-[0px] md:text-base p-[6px] text-[10px]">Resturant</div>

              <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[6px] text-[10px]">55 listing</div>
            </div>
            <div className="relative">
              <img className="rounded-2xl filte" src="../images/categories-5.png" alt="img" />
              <div className="absolute top-[-10px] right-[0] round bg-green-700 text-white md:p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:top-[0px] md:text-base p-[6px] text-[10px]">Warehouse</div>

              <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[6px] text-[10px]">15 listing</div>
            </div>
          </div>
          <div className="relative">
            <img className="rounded-2xl filte rounded-2xl h-[100%] w-[100%] filte object-cover" src="../images/categories-6.png" alt="img" />
            <div className="absolute top-[-10px] right-[0] round bg-green-700 text-white md:p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:top-[0px] md:text-base p-[10px] text-[12px]">House</div>

            <div className="absolute bottom-[0px] round-left bg-green-700 text-white p-3 cursor-pointer hover:text-green-700 hover:bg-white hover:shadow-2xl transit md:text-base p-[10px] text-[12px]">95 listing</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories