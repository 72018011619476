import React from 'react'

const Card = ({ name, desc, imgUrl, fb, tw, li, ins }) => {
  return (
    <>
      <div className="items-start flex flex-col shadow-2xl bg-white pt-4 pb-8 space-y-5 px-4 rounded-lg">
        <img className='w-[100%] rounded-md' src={`../images/${imgUrl}`} alt="img" />
        <p className='font-bold'>{name}</p>
        <p className='text-sm'>{desc}</p>
        <div className='flex justify-start gap-4'>
          <img className='w-[9%] inline-block' src={`../images/${fb}`} alt="img" />
          <img className='w-[9%] rounded-full inline-block' src={`../images/${tw}`} alt="img" />
          <img className='w-[9%] inline-block' src={`../images/${li}`} alt="img" />
          <img className='w-[9%] inline-block' src={`../images/${ins}`} alt="img" />
        </div>
      </div>
    </>
  )
}

export default Card