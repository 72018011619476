import React from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import { useEffect } from 'react'
import Box from "./Box"
import Marquee from "react-fast-marquee"
import { Data } from './Data-1'

const Trust = ({ btn }) => {
  useEffect(() => {
    Aos.init()
  }, [])
  const boxElement = Data.map((card) => {
    return (
      <div data-aos="flip-up">
        <Box key={card.id} carDetail="transparent-btn cursor-pointer" carDetail1="filled-btn cursor-pointer shadow transit" card={card} />
      </div>
    )
  })
  return (
    <>
      <div className='padding'>
        <h1 className='text-center font-bold grade md:text-2xl firstlowfontw-lh content-1' data-aos="fade-up">TRUSTED AND PARTNERED BY THE BEST</h1>
        <Marquee className="marquee-container hidden py-10 px-2" speed={60} gradient={false} pauseOnHover>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">travella</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Stream Streak</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Airbnb</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Netflix</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Google</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Telegram</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Meta</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Streamstreak</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Glow</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Uber</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Docker</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Node JS</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Mongo DB</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Next JS</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">React JS</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Go</strong>
          <strong className="font-bold text-2xl mr-4 text-[white] text-shadow">Express JS</strong>
        </Marquee>
      </div>
      <div className='bg-green-100 min-h-[600px] py-[40px] px-[20px] md:padding'>
        <div className="flex-cont">
          <h1 className='text-center md:text-2xl firstlowfontw-lh font-bold grade content-1 mb-[100px]' data-aos="fade-right">OUR FEATURED PROPERTIES</h1>
          <div className='grid-box'>
            {boxElement}
          </div>
        </div>
        <div className='text-center mt-[70px] text-white px-2 rounded-md hover:text-green-700 hover:bg-white py-2 transit cursor-pointer bg-green-700 shadow w-[210px] mx-auto' data-aos="flip-right">{btn}</div>
      </div>
    </>
  )
}

export default Trust